import React, {useContext, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Col, Row, Spinner} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';

import Outcomes from 'components/dashboards/default/Outcomes';
import Journeys from 'components/dashboards/default/Journeys';
import Timeline from 'components/dashboards/default/Timeline';
import AppContext from 'context/Context';
import CustomerApi from 'api/customer';
import OutcomesApi from 'api/outcomes';
import BucketsApi from 'api/buckets';
import JourneysApi from 'api/journeys';
import apiRetrier from 'api/api_retrier';
import {debounce} from 'chart.js/helpers';
import {NullFunction, timestamp} from 'utilities/Convenience';
import {deltaMap} from 'components/common/Analyze';
import {applyOutcomeFilters, applyJourneyFilters} from 'utilities/Filtering';
import {getDataCounts} from 'utilities/CollectingOutcomeCounts';

const currentTime = timestamp();
const day = 60 * 60 * 24;

const ct0 = currentTime;
const ct1 = currentTime - (1 * day);
const ct2 = currentTime - (1 * day) - 2;
const ct3 = currentTime - (1 * day) - 3;
const ct4 = currentTime - (2 * day) - 1;
const ct5 = currentTime - (2 * day) - 2;
const ct6 = currentTime - (2 * day) - 3;
const ct7 = currentTime - (3 * day) - 1;
const ct8 = currentTime - (3 * day) - 2;
const ct9 = currentTime - (4 * day) - 1;
const ct10 = currentTime - (5 * day) - 1;
export const demoOutcomes = [
    {
        id: 1, value: 11, name: 'Subscription Renewal:success:Premium - Annual', color: 'primary', uuid: '1',
        series: [
            ct0,
            ct1,
            ct2,
            ct3,
            ct4,
            ct5,
            ct6,
            ct7,
            ct8,
            ct9,
            ct10
        ],
        platforms: {
            [ct0]: null,
            [ct1]: null,
            [ct2]: {
                softwareVersion: '5.1.5',
                deviceModel: 'Linux',
                operatingSystemName: 'Linux',
                operatingSystemVersion: '5.10.104-linuxkit'
            },
            [ct3]: {
                softwareVersion: '5.1.5',
                deviceModel: 'Linux',
                operatingSystemName: 'Linux',
                operatingSystemVersion: '5.10.104-linuxkit'
            },
            [ct4]: {
                softwareVersion: '5.1.5',
                deviceModel: 'Linux',
                operatingSystemName: 'Linux',
                operatingSystemVersion: '5.10.104-linuxkit'
            },
            [ct5]: {
                softwareVersion: '5.1.5',
                deviceModel: 'Linux',
                operatingSystemName: 'Linux',
                operatingSystemVersion: '5.10.104-linuxkit'
            },
            [ct6]: {
                softwareVersion: '5.1.5',
                deviceModel: 'Linux',
                operatingSystemName: 'Linux',
                operatingSystemVersion: '5.10.104-linuxkit'
            },
            [ct7]: {
                softwareVersion: '5.1.5',
                deviceModel: 'Linux',
                operatingSystemName: 'Linux',
                operatingSystemVersion: '5.10.104-linuxkit'
            },
            [ct8]: {
                softwareVersion: '5.1.5',
                deviceModel: 'Linux',
                operatingSystemName: 'Linux',
                operatingSystemVersion: '5.10.104-linuxkit'
            },
            [ct9]: null,
            [ct10]: null,

        },
    },
    {
        id: 2, value: 4, name: 'Initial Subscription:success:Basic - Annual', color: 'info', uuid: '2', series: [
            ct0,
            currentTime - deltaMap['Last 7 days'] + deltaMap['Last day'] + 1,
            currentTime - deltaMap['Last 7 days'] + deltaMap['Last day'] + 2,
            currentTime - deltaMap['Last 2 weeks'] + deltaMap['Last day'] + 3,
            currentTime - deltaMap['Last 2 weeks'] + deltaMap['Last day'] + 4
        ]
    },
    {
        id: 4,
        value: 3,
        name: 'Subscription Renewal:fail:Standard - Annual',
        uuid: '4',
        color: '400',
        series: [
            ct3,
            ct2,
            ct1
        ],
        platforms: {
            [ct3]: null,
            [ct2]: {
                softwareVersion: '5.1.5',
                deviceModel: 'Linux',
                operatingSystemName: 'Linux',
                operatingSystemVersion: '5.10.104-linuxkit'
            },
            [ct1]: null
        },
        tags: {
            [ct3]: [],
            [ct2]: [],
            [ct1]: []
        }
    },
    {
        id: 5,
        value: 3,
        name: 'Initial Subscription:success:Basic - Annual',
        uuid: '5',
        color: '400',
        series: [
            ct6,
            ct5,
            ct4
        ],
        platforms: {
            [ct6]: null,
            [ct5]: {
                softwareVersion: '5.1.5',
                deviceModel: 'Linux',
                operatingSystemName: 'Linux',
                operatingSystemVersion: '5.10.104-linuxkit'
            },
            [ct4]: null
        },
        tags: {
            [ct6]: [],
            [ct5]: [],
            [ct4]: []
        }
    },
    {
        id: 6,
        value: 2,
        name: 'Account Signup:fail:Another Xenon SaaS Outcome',
        uuid: '6',
        color: '500',
        series: [
            ct8,
            ct7
        ],
        platforms: {
            [ct8]: null,
            [ct7]: {
                softwareVersion: '5.1.5',
                deviceModel: 'Linux',
                operatingSystemName: 'Linux',
                operatingSystemVersion: '5.10.104-linuxkit'
            }
        },
        tags: {
            [ct8]: [],
            [ct7]: []
        }
    },
    {
        id: 3, value: 1, name: 'Initial Subscription:fail:Basic - Annual', color: '300', uuid: '3', series: [
            currentTime - deltaMap['Last month'] + deltaMap['Last day'] + 1
        ]
    },
    {
        id: 7, value: 1, name: 'Subscription Renewal:fail:Paused - Basic Annual', color: '300', uuid: '7', series: [
            currentTime - deltaMap['Last month'] + deltaMap['Last day'] + 1
        ]
    },
    {
        id: 8, value: 1, name: 'Subscription Upsold:success:Upsold - Premium Annual', color: '300', uuid: '8', series: [
            currentTime - deltaMap['Last month'] + deltaMap['Last day'] + 1
        ]
    },
    {
        id: 9, value: 1, name: 'Subscription Upsold:fail:Downsell - Basic Monthly', color: '300', uuid: '9', series: [
            currentTime - deltaMap['Last month'] + deltaMap['Last day'] + 1
        ]
    },
    {
        id: 10, value: 1, name: 'Referral:success:Referred - By Text Message', color: '300', uuid: '10', series: [
            currentTime - deltaMap['Last month'] + deltaMap['Last day'] + 1
        ]
    },
    {
        id: 11, value: 1, name: 'Referral:fail:Declined - By Text Message', color: '300', uuid: '11', series: [
            currentTime - deltaMap['Last month'] + deltaMap['Last day'] + 1
        ]
    },
    {
        id: 12, value: 1, name: 'Application Installation:success:Installed', color: '300', uuid: '12', series: [
            currentTime - deltaMap['Last month'] + deltaMap['Last day'] + 1
        ]
    },
    {
        id: 13, value: 1, name: 'Application Installation:fail:Not Installed', color: '300', uuid: '13', series: [
            currentTime - deltaMap['Last month'] + deltaMap['Last day'] + 1
        ]
    }
];
const demoJourneys = [
    {
        id: 1,
        title: 'DCB123',
        occurrences: 13,
        series: [
            ct1,
            ct2,
            ct3,
            currentTime - deltaMap['Last 7 days'] + deltaMap['Last day'] + 4,
            currentTime - deltaMap['Last 7 days'] + deltaMap['Last day'] + 5,
            currentTime - deltaMap['Last 7 days'] + deltaMap['Last day'] + 6,
            currentTime - deltaMap['Last 7 days'] + deltaMap['Last day'] + 7,
            currentTime - deltaMap['Last 7 days'] + deltaMap['Last day'] + 8,
            currentTime - deltaMap['Last 2 weeks'] + deltaMap['Last day'] + 9,
            currentTime - deltaMap['Last 2 weeks'] + deltaMap['Last day'] + 10,
            currentTime - deltaMap['Last 2 weeks'] + deltaMap['Last day'] + 11,
            currentTime - deltaMap['Last 2 weeks'] + deltaMap['Last day'] + 12,
            currentTime - deltaMap['Last 2 weeks'] + deltaMap['Last day'] + 13,
        ],
        steps: [
            {
                'action': '{"promotionName":"GOOGLE_ANDROID","planType":"BASIC_MONTHLY"}',
                'outcome': 'Membership Renewal - Google Android',
                'timestamp': 1660818066.779359,
                'delta': 0,
                'deltas': [
                    0,
                    0,
                    0,
                    2098.999418258667,
                    2114.5979981422424,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    2592183.6556642056,
                    2592146.1161220074,
                    0,
                    0,
                    0,
                    0,
                    0
                ],
                'counts': [
                    1,
                    1,
                    1,
                    1,
                    1,
                    1,
                    1,
                    1,
                    1,
                    1,
                    1,
                    1,
                    1,
                    1,
                    1,
                    1,
                    1,
                    1,
                    1,
                    1,
                    1
                ]
            }
        ]
    },
    {
        id: 2,
        title: 'DCB501',
        occurrences: 10,
        series: [
            currentTime - deltaMap['Last 7 days'] + deltaMap['Last day'] + 1,
            currentTime - deltaMap['Last 7 days'] + deltaMap['Last day'] + 2,
            currentTime - deltaMap['Last 7 days'] + deltaMap['Last day'] + 3,
            currentTime - deltaMap['Last 7 days'] + deltaMap['Last day'] + 4,
            currentTime - deltaMap['Last 7 days'] + deltaMap['Last day'] + 5,
            currentTime - deltaMap['Last 2 weeks'] + deltaMap['Last day'] + 6,
            currentTime - deltaMap['Last 2 weeks'] + deltaMap['Last day'] + 7,
            currentTime - deltaMap['Last 2 weeks'] + deltaMap['Last day'] + 8,
            currentTime - deltaMap['Last 2 weeks'] + deltaMap['Last day'] + 9,
            currentTime - deltaMap['Last 2 weeks'] + deltaMap['Last day'] + 10,
        ],
        steps: [
            {
                'action': '{"promotionName":"GOOGLE_ANDROID","planType":"STANDARD_MONTHLY"}',
                'outcome': 'Membership Renewal - Google Android',
                'timestamp': 1661422767.293329,
                'delta': 0,
                'deltas': [
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0
                ],
                'counts': [
                    1,
                    1,
                    1,
                    1,
                    1,
                    1,
                    1,
                    1,
                    1,
                    1,
                    1
                ]
            }
        ]
    },
    {
        id: 3,
        title: 'DCB250',
        occurrences: 7,
        series: [
            currentTime - deltaMap['Last month'] + deltaMap['Last day'] + 1,
            currentTime - deltaMap['Last month'] + deltaMap['Last day'] + 2,
            currentTime - deltaMap['Last month'] + deltaMap['Last day'] + 3,
            currentTime - deltaMap['Last month'] + deltaMap['Last day'] + 4,
            currentTime - deltaMap['Last month'] + deltaMap['Last day'] + 5,
            currentTime - deltaMap['Last month'] + deltaMap['Last day'] + 6,
            currentTime - deltaMap['Last month'] + deltaMap['Last day'] + 7,
        ],
        steps: [
            {
                'action': '{"promotionName":"GOOGLE_ANDROID","planType":"PREMIUM_MONTHLY"}',
                'outcome': 'Membership Renewal - Google Android',
                'timestamp': 1661250014.259273,
                'delta': 0,
                'deltas': [
                    0,
                    0,
                    0,
                    2851260.5379493237,
                    0,
                    0,
                    0
                ],
                'counts': [
                    1,
                    1,
                    1,
                    1,
                    1,
                    1,
                    1
                ]
            }
        ],
        links: ['1234abc', '5678def']
    },
    {
        id: 4,
        title: 'DCB333',
        occurrences: 2,
        series: [
            currentTime - deltaMap['Last 2 months'] + deltaMap['Last day'] + 1,
            currentTime - deltaMap['Last 2 months'] + deltaMap['Last day'] + 2,
        ],
        steps: [
            {
                'category': 'Recipe Action',
                'action': 'View',
                'timestamp': 1665272388.859231,
                'count': 4,
                'delta': 0,
                'deltas': [
                    0,
                    0,
                    0
                ],
                'counts': [
                    4,
                    7,
                    10
                ]
            },
            {
                'action': '{"promotionName":"GOOGLE_ANDROID","planType":"BASIC_MONTHLY"}',
                'outcome': 'Membership Renewal - Google Android',
                'timestamp': 1666693539.9933882,
                'delta': 1421151.1341571808,
                'deltas': [
                    1421151.1341571808,
                    8495062.229480982,
                    1531518.3781950474
                ],
                'counts': [
                    1,
                    1,
                    1
                ]
            }
        ],
        links: ['1234abc']
    }
];
const demoTimeline = [
    {
        category: 'Page View',
        action: 'Landing',
        timestamp: 1646349689.0,
        deltas: [0, 0]
    },
    {
        category: 'Page View',
        action: '/library/test',
        timestamp: 1646349691.0,
        deltas: [2, 2.1]
    },
    {
        category: 'Submit',
        action: 'Email Credentials',
        timestamp: 1646349701.0,
        deltas: [10, 9]
    },
    {
        funnel: 'Nurture Leads',
        action: 'Post',
        timestamp: 1646349701.0,
        deltas: [0, 0]
    },
    {
        category: 'Button Click',
        action: 'Get Assessment',
        timestamp: 1646349721.0,
        deltas: [15, 25]
    },
    {
        category: 'Page View',
        action: '/assessment',
        timestamp: 1646349731.0,
        deltas: [10, 10]
    },
    {
        category: 'Submit',
        action: 'Twitter Handle',
        timestamp: 1646349741.0,
        deltas: [8, 12]
    },
    {
        funnel: 'Capture Leads',
        action: 'Assessment',
        timestamp: 1646349763.0,
        deltas: [22, 22]
    },
    {
        category: 'Email',
        action: 'Sent: Assessment',
        timestamp: 1646350541.0,
        deltas: [778, 779]
    },
    {
        category: 'Email',
        action: 'Opened: Assessment',
        timestamp: 1646351321.0,
        deltas: [780, 780]
    },
    {
        category: 'Page View',
        action: '/assessment/<uuid>',
        timestamp: 1646352575.0,
        deltas: [1254, 900, 2000]
    },
    {
        category: 'Button Click',
        action: 'Free Trial',
        timestamp: 1646352802.0,
        deltas: [227, 228]
    },
    {
        outcome: 'Trial Signup',
        action: 'Free Trial',
        timestamp: 1646352808.0,
        deltas: [6, 6]
    }
];

class timelinesGenerator {
    constructor(timeslines) {
        this.timelines = timeslines;
    }

    difference(setA, setB) {
        let _difference = new Set(setA);
        for (let elem of setB) {
            _difference.delete(elem);
        }
        return _difference;
    }

    setKeysInTimelines(keysToAdd, value, setter) {
        let newTimelines = JSON.parse(JSON.stringify(this.timelines));
        keysToAdd.map((element) => newTimelines[element] = value);
        if (keysToAdd.length) {
            setter(newTimelines);
        }
    }

    generate(selectedJourneys, value, setTimelines) {
        const keysToAdd = this.timelinesToGet(selectedJourneys);
        this.setKeysInTimelines(keysToAdd, value, setTimelines);
    }

    timelinesToGet(selectedJourneys) {
        return [...this.difference(new Set(selectedJourneys), new Set(Object.keys(this.timelines)))];
    }
}

export function TimelinesGenerator(timelines) {
    return new timelinesGenerator(timelines);
}

const defaultSpinning = (identifier) => {
    return (<div key={identifier} className={'d-flex justify-content-center ' + identifier}>
        <Spinner animation="border" role="status" style={{width: '5rem', height: '5rem'}}>
            <span className="visually-hidden">Loading...</span>
        </Spinner>
    </div>);
};

const setDefaultSpinning = () => (defaultSpinning);
const setNoSpinning = () => (NullFunction);

const Dashboard = ({
                       outcomesApi_ = OutcomesApi,
                       bucketsApi_ = BucketsApi,
                       journeysApi_ = JourneysApi,
                       outcome_ = {name: '', uuid: ''},
                       outcomes_ = [],
                       journey_,
                       journeys_ = [],
                       errorLog_ = console.log,
                       timestampFilterDebounce = 500,
                       windowSizeUpdateDebounce = 50,
                       initialFilters = {},
                       scrollAfterFetchJourneys_ = NullFunction,
                       scrollAfterFetchTimeline_ = NullFunction,
                       maxApiRetry_ = -1,
                       spinning_ = setDefaultSpinning
                   }) => {
    // const [customer, setCustomer] = useState(customer_);
    const [selectedOutcome, setSelectedOutcome] = useState(outcome_);
    const [selectedJourneys, setSelectedJourneys] = useState(journey_ ? [journey_] : []);
    const [outcomes, setOutcomes] = useState(outcomes_);
    const [journeys, setJourneys] = useState(journeys_);
    const [timelines, setTimelines] = useState({});
    const [filters, setFilters] = useState(addInitialFilters());
    const [mode, setMode] = useState('customer');
    const [maxTimelineWidth, setMaxTimelineWidth] = useState(0);
    const [analyzeMode, setAnalyzeMode] = useState(false);
    const [outcomeDataCounts, setOutcomeDataCounts] = useState(
        {
            days: [],
            weeks: [],
            months: [],
            quarter: []
        });

    const [spinning, setSpinning] = useState(spinning_);
    const scrollAfterFetchJourneys = useRef(scrollAfterFetchJourneys_);
    const scrollAfterFetchTimeline = useRef(scrollAfterFetchTimeline_);
    const previousOutcome = useRef(outcome_);
    const navigate = useNavigate();
    const topRef = useRef(null);
    const maxApiTime = 180000; //3 minutes
    const [filteredOutcomes, setFilteredOutcomes] = useState(applyOutcomeFilters(outcomes, filters));
    const [filteredJourneys, setFilteredJourneys] = useState(applyJourneyFilters(journeys, filters));

    const {
        config: {
            customerCode,
            apiUrl
        }
    } = useContext(AppContext);

    function setMaxWidth() {
        if (topRef.current) {
            setMaxTimelineWidth(topRef.current.offsetWidth);
        }
    }

    useEffect(() => {
        // Handler to call on window resize
        let resizeHandler = debounce(function () {
            setMaxWidth();
        }, windowSizeUpdateDebounce);
        // Add event listener
        window.addEventListener('resize', resizeHandler);
        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', resizeHandler);
    }, [windowSizeUpdateDebounce]); // Empty array ensures that effect is only run on mount

    useEffect(() => {
        setOutcomeDataCounts(getDataCounts(filteredOutcomes));
    }, [filteredOutcomes]);

    useEffect(() => {
        setMaxWidth();
        setFilters(addInitialFilters());

        function setupDemo() {
            if (mode !== 'demo') {
                setOutcomes(demoOutcomes);
                setJourneys(demoJourneys);
                setFilteredOutcomes(applyOutcomeFilters(demoOutcomes, filters));
                setFilteredJourneys(applyJourneyFilters(demoJourneys, filters));
                setMode('demo');
                scrollAfterFetchJourneys.current();
                scrollAfterFetchTimeline.current();
                setSpinning(setNoSpinning);
            }
            TimelinesGenerator(timelines).generate(selectedJourneys, demoTimeline, setTimelines);
        }

        function getOutcomes(data) {
            apiRetrier(() => outcomesApi_(apiUrl).fetch({data}), maxApiRetry_, maxApiTime)
                .then(response => {
                    setOutcomes(response);
                    const filteredResponse = applyOutcomeFilters(response, filters);
                    setFilteredOutcomes(filteredResponse);
                    setMode('customer');
                    setSpinning(setNoSpinning);
                })
                .catch(error => {
                    errorLog_(error);
                });
        }

        function getBuckets(data) {
            previousOutcome.current = selectedOutcome;
            apiRetrier(() => bucketsApi_(apiUrl).fetch({data}), maxApiRetry_, maxApiTime)
                .then(response => {
                    setJourneys(response);
                    setFilteredJourneys(applyJourneyFilters(response, filters));
                    scrollAfterFetchJourneys.current();
                    setSpinning(setNoSpinning);
                })
                .catch(error => {
                    errorLog_(error);
                });
        }

        function getTimelines(data, bucket, generator) {
            apiRetrier(() => journeysApi_(apiUrl).fetch({data}), maxApiRetry_, maxApiTime)
                .then(response => {
                    if (response.length) generator.generate([bucket], response[0], setTimelines);
                    scrollAfterFetchTimeline.current();
                    setSpinning(setNoSpinning);
                })
                .catch(error => {
                    errorLog_(error);
                });
        }

        if (customerCode === '') {
            navigate('/');
            return;
        }
        if (customerCode === 'demo') {
            setupDemo();
            return;
        }

        let data = {token: customerCode};

        // console.log('Customer is -' + customer + '-' );
        // if (customer === '') {
        //     console.log('getting customer');
        //     getCustomer(data);
        //     console.log('getCustomer', customer);
        //     return;
        // }

        if (outcomes.length < 1) {
            getOutcomes(data);
            return;
        }

        // if consolidationNecessary (make sure to flip this)

        if (!selectedOutcome.uuid || selectedOutcome.uuid === '') return;

        data.outcomeHash = selectedOutcome.uuid;
        if (journeys.length < 1 || (journeys.length > 0 && previousOutcome.current !== selectedOutcome)) {
            getBuckets(data);
            return;
        }

        const timelinesToGet = TimelinesGenerator(timelines).timelinesToGet(selectedJourneys);
        if (!timelinesToGet.length) return;

        timelinesToGet.map((bucket) => {
            data.bucket = bucket;
            getTimelines(data, bucket, TimelinesGenerator(timelines));
            return true;
        });

    }, [
        navigate, customerCode, mode, apiUrl, maxApiRetry_,
        outcomesApi_, bucketsApi_, journeysApi_, errorLog_,
        selectedJourneys, selectedOutcome,
        outcomes, journeys, timelines, topRef, setMaxTimelineWidth
    ]);

    function addInitialFilters() {
        const initialAlteredFilters = initialFilters;
        // if (!initialAlteredFilters.hasOwnProperty('consolidate-journeys')) {
        //     initialAlteredFilters['consolidate-journeys'] = {
        //         appliesTo: new Set(['journeys']),
        //         applyFilter: (journeys) => consolidateJourneys(journeys)
        //     };
        // }
        return initialAlteredFilters;
    }

    if (customerCode === '') return null;

    return (
        <div className={'default-dashboard'}>
            <Row className="g-3 mb-3">
                <Col ref={topRef}>
                    <Outcomes
                        onChange={(newOutcome, deferred) => {
                            setJourneys([]);
                            setSelectedJourneys([]);
                            setTimelines({});
                            setSelectedOutcome(newOutcome);
                            setMode('customer');
                            scrollAfterFetchJourneys.current = deferred;
                            setSpinning(setDefaultSpinning);
                        }}
                        data={filteredOutcomes}
                        uuidToOutcomeName={(() => {
                            const uuidToOutcomeName = {};
                            outcomes.forEach(outcome => (uuidToOutcomeName[outcome.uuid] = outcome.name));
                            return uuidToOutcomeName;
                        })()}
                        dataCounts={outcomeDataCounts}
                        selectedOutcome={selectedOutcome}
                        radius={['100%', '87%']}
                        filterMap={filters}
                        onFilter={(key, newFilter, appliesTo) => {
                            const alteredFilters = filters;
                            alteredFilters[key] = {
                                appliesTo: new Set([appliesTo])
                            };
                            alteredFilters[key].applyFilter = newFilter;
                            setFilters(alteredFilters);
                            const filteredOutcomes = applyOutcomeFilters(outcomes, filters);
                            setFilteredOutcomes(filteredOutcomes);
                            setOutcomeDataCounts(getDataCounts(filteredOutcomes));
                            setFilteredJourneys(applyJourneyFilters(journeys, filters));
                        }}
                        timestampFilterDebounce={timestampFilterDebounce}
                        onAnalyze={setAnalyzeMode}
                        analyzing={analyzeMode}
                        onOutcomeCsv={async (selected) => {
                            let data = {token: customerCode};
                            data.outcomeHash = selected.uuid;
                            data.includeSteps = true;
                            data.since = timestamp() - 15780000;
                            let bucket = customerCode === 'demo' ? demoJourneys :
                                await apiRetrier(() => bucketsApi_(apiUrl).fetch({data}));
                            return applyOutcomeFilters(bucket, filters);
                        }}
                        spinning={spinning}
                    />
                </Col>
            </Row>

            <Row className="g-3 mb-3">
                <Col>
                    <Journeys
                        journeys={filteredJourneys}
                        outcome={selectedOutcome}
                        selected={selectedJourneys}
                        onAdd={(toAdd, deferred) => {
                            const journeysSet = new Set(selectedJourneys);
                            journeysSet.add(toAdd);
                            setSelectedJourneys([...journeysSet]);
                            scrollAfterFetchTimeline.current = deferred;
                            setSpinning(setDefaultSpinning);
                        }}
                        onRemove={(toRemove) => {
                            const journeysSet = new Set(selectedJourneys);
                            journeysSet.delete(toRemove);
                            setSelectedJourneys([...journeysSet]);
                        }}
                        onReselect={(reselected) => {
                            setSelectedJourneys(reselected);
                            setSpinning(setDefaultSpinning);
                        }}
                        analyzing={analyzeMode}
                        spinning={spinning}
                    />
                </Col>
            </Row>
            <Row>
                {
                    (() => {
                        if (Object.keys(timelines).length < 1 && selectedOutcome.name === '') return null;

                        const timelinesKeys = Object.keys(timelines);
                        const numTimelines = selectedJourneys.length;
                        let numerator = maxTimelineWidth;
                        for (let denominator = 1; denominator <= numTimelines; denominator++) {
                            if (maxTimelineWidth / denominator > 320) {
                                numerator = maxTimelineWidth / denominator;
                                continue;
                            }
                            break;
                        }
                        if (numTimelines === 1 && !selectedJourneys.some((journey) => timelinesKeys.includes(journey))) {
                            return spinning('timeline-spinner');
                        }
                        const maxWidth = numerator / maxTimelineWidth * 100;
                        return selectedJourneys.map((journey, index) => {
                            if (!timelinesKeys.includes(journey)) return null;
                            return (
                                <Col key={index} style={{maxWidth: maxWidth.toString() + '%'}}
                                     className={'timeline-display-' + index.toString()}>
                                    <Timeline
                                        timeline={timelines[journey]}
                                        journey={journey}
                                        journeys={journeys}
                                        outcomes={outcomes}
                                        index={index}
                                        onRemove={(toRemove) => {
                                            const journeysSet = new Set(selectedJourneys);
                                            journeysSet.delete(toRemove);
                                            setSelectedJourneys([...journeysSet]);
                                        }}
                                        onClickOutcome={(newOutcome) => {
                                            setJourneys([]);
                                            setFilteredJourneys([]);
                                            setSelectedJourneys([]);
                                            setTimelines({});
                                            setSelectedOutcome(newOutcome);
                                            setMode('customer');
                                        }}
                                    />
                                </Col>
                            );
                        });
                    })()
                }
            </Row>
        </div>
    );
};
Dashboard.propTypes = {
    // customerApi_: PropTypes.func,
    outcomesApi_: PropTypes.func,
    bucketsApi_: PropTypes.func,
    journeysApi_: PropTypes.func,
    outcome_: PropTypes.object,
    outcomes_: PropTypes.array,
    journey_: PropTypes.string,
    journeys_: PropTypes.array,
    errorLog_: PropTypes.func,
    initialFilters: PropTypes.object,
    windowSizeUpdateDebounce: PropTypes.number,
    timestampFilterDebounce: PropTypes.number,
    scrollAfterFetchJourneys_: PropTypes.func,
    scrollAfterFetchTimeline_: PropTypes.func,
    maxApiRetry_: PropTypes.number,
    spinning_: PropTypes.func
};

export default Dashboard;
